
<template>
  <v-container>
    <v-alert text prominent type="error" icon="mdi-cloud-alert" v-if="alertError!=''">
      {{alertError}}
    </v-alert>
    <v-alert text prominent type="success" icon="mdi-cloud-alert" v-if="alertSuccess!=''">
      {{alertSuccess}}
    </v-alert>
    <v-card outlined class="pa-5 mb-2">
        <v-row>
            <v-col>
                <v-row>
                    <v-col cols="5 my-lg-auto text-center">
                        <p class="text-h6 mb-1"><v-icon>mdi-account-supervisor-circle</v-icon>Country</p>
                    </v-col>
                    <v-col cols="7">
                        <v-select v-model="country" outlined :items="countries" item-text="name" menu-props="auto" label="Select country" hide-details return-object single-line @change="SelectState"></v-select>
                    </v-col>
                </v-row>
            </v-col>
            <v-col>
                <v-row>
                    <v-col cols="5 my-lg-auto text-center">
                        <p class="text-h6 mb-1"><v-icon>mdi-account-supervisor-circle</v-icon>State</p>
                    </v-col>
                    <v-col cols="7">
                        <v-select v-model="state" outlined :items="states" item-text="name" menu-props="auto" label="Select State" hide-details return-object single-line @change="SelectSchoolDistrict"></v-select>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-card>
    <v-card>
        <v-toolbar flat color="primary" dark>
            <v-toolbar-title>List School Districts</v-toolbar-title>
             <v-spacer></v-spacer>

            <v-btn outlined @click="AddSchool">
              <v-icon>mdi-plus</v-icon>Add School District               
            </v-btn>
        </v-toolbar>
        <v-list subheader two-line>
            <!-- <template v-for="(schoolTab, index) in schools">
                
            </template> -->
            <v-list-item v-for="district in SchoolDistrict" :key="district.title">

                <v-list-item-content>
                    <v-list-item-title v-text="district.name"></v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                    <v-btn icon @click="$event=>editSchool(district)">
                        <v-icon color="grey lighten-1">mdi-pencil</v-icon>
                    </v-btn>
                </v-list-item-action>
            </v-list-item>
            <v-divider></v-divider>
        </v-list>
    </v-card>
    <v-dialog v-model="dialogSchoolDistrict" max-width="1000px">
        <v-card>
            <v-form ref="form" v-model="valid"  @submit.prevent="save" lazy-validation>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field v-model="school.name" :rules="nameRules" label="Name" prepend-icon="mdi-account" required></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close">
                    Cancel
                  </v-btn>
                  <v-btn color="blue darken-1" text type="submit" :disabled="disabledSave">
                    Save
                  </v-btn>
                </v-card-actions>
                </v-form>
              </v-card>
            </v-dialog>
    <v-dialog v-model="dialogLoad" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Wait please
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-bottom-sheet v-model="sheet">
      <v-sheet
        class="text-center"
        height="200px"
      >
        <v-btn
          class="mt-6"
          text
          color="red"
          @click="sheet = !sheet"
        >
          close
        </v-btn>
        <div class="py-3">
          This user is disabled
        </div>
      </v-sheet>
    </v-bottom-sheet>
  </v-container>
</template>
<script>
import { db, functions } from '../../../plugins/firebase'
export default {
  data () {
    return {
      users: [],
      school: {
        name: '',
        country: '',
        state: '',
      },
      nameRules: [
        v => (v && v.length >= 2) || 'Minimum length is 2 characters',
        v => (v && v.length <= 60) || 'Name must be less than 60 characters'
      ],
      clearSchool: {
        name: '',
        country: '',
        state: '',
      },
      country: '',
      countries: [],
      state: '',
      states: [],
      schools: [],
      editedUID: -1,
      dialogSchoolDistrict: false,
      dialogLoad: false,
      valid: true,
      PickerExpiration: false,
      alertError: '',
      alertSuccess: '',
      showPassword: false,
      table: 'schoolAdmin',
      sheet: false,
      SchoolDistrict: [],
      disabledSave: false
    }
  },
  created () {
    this.ListCountries();
  },
  methods: {
    async ListCountries () {
      await db.collection('SchoolsData').doc('RegionSchoolD').get().then((doc) => {

        let Countries = doc.data().Countries;
        let ids = Object.keys(Countries);
        let newCountries = [];
        ids.forEach(element => {
          newCountries.push({ id: element, name: Countries[element] })
        });
        this.countries = newCountries;
      }).catch((error) => {
        console.log(error)
      });
    },
    AddSchool () {
      if (this.country!='' && this.state!='')
        this.dialogSchoolDistrict = true;
      else{
        this.alertError = "Necesita selecionar Country y State";
        let principal = this;
        setTimeout(function () { principal.alertError = '' }, 3000);
      }        

    },
    editSchool(district){
      this.editedUID=district.id
      console.log(district)
      this.school.name=district.name
      this.school.id=district.id
      this.dialogSchoolDistrict=true 
    },
    close () {
      this.dialogSchoolDistrict = false
      this.$refs.form.resetValidation()
      this.$nextTick(() => {
        this.school = Object.assign({}, this.clearSchool)
        this.editedUID = -1
      })
    },    
    save () {
      this.disabledSave=true;
      if (!this.$refs.form.validate()) return;
      if (this.editedUID !== -1) {
        this.dialogLoad = true;
        this.updateData().then(response => {
          console.log(response);
          if (response.result) {
            this.alertSuccess = "se actualizo correctamente ";
            let principal = this;
            setTimeout(function () { principal.alertSuccess = '' }, 3000);
          } else {
            let principal = this;
            if (Object.prototype.hasOwnProperty.call(response.message, 'code')) {
              switch (response.message.code) {
                case 'not-found':
                  this.alertError = 'no se encontro el documento';
                  break;
                default:
                  this.alertError = response.message.code;
              }
            } else {
              this.alertError = response.message;
            }
            setTimeout(function () { principal.alertError = '' }, 3000);
          }
          this.dialogLoad = false
          this.disabledSave=false;
          this.close()
        });
      } else {
        this.dialogLoad = true;
        this.createData().then((response) => {
          console.log(response);
          if (!response.result) {
            this.alertError = response.message;
            let principal = this;
            setTimeout(function () { principal.alertError = '' }, 3000);
          } else {
            this.alertSuccess = "se creo correctamente ";
            let principal = this;
            setTimeout(function () { principal.alertSuccess = '' }, 3000);
          }
          this.dialogLoad = false;
          this.disabledSave=false;
          this.close()
        });
      }
      
      this.$refs.form.resetValidation()
    },
    validate () {
      this.$refs.form.validate()
    },
    // llamadas a firebase
    async createData () {      
      let answer = { result: '', message: '' }
      try {
        let uidSuperAdmin = this.$store.state.user;
        if (uidSuperAdmin == null) {
          throw new Error('no existe el usuario');
        }
        let schoolCreate=this.school
        let docRef=await db.collection('SchoolsData').doc('RegionSchoolD').collection('Countries').doc(this.country.id).collection('States').doc(this.state.id).collection('SchoolDistricts').add({
          "Schools": {},
        })
        await db.collection('SchoolsData').doc('RegionSchoolD').collection('Countries').doc(this.country.id).collection('States').doc(this.state.id).update({
          [`SchoolDistricts.${[docRef.id]}`]: schoolCreate.name
        })
        this.SelectSchoolDistrict();
        answer.result = true;              
      } catch (error) {
        console.log(error)
        answer.result = false;
        answer.message = error;        
      }
      return answer;

    },
    async updateData () {      
      let answer = { result: '', message: '' }
      try {
        await db.collection('SchoolsData').doc('RegionSchoolD').collection('Countries').doc(this.country.id).collection('States').doc(this.state.id).update({
          [`SchoolDistricts.${[this.school.id]}`]: this.school.name
        })
        this.SelectSchoolDistrict();
        answer.result=true
      } catch (error) {
        answer.result = false;
        answer.message = error;
        console.log('entro al log general');
      }
      return answer;
    },
    async SelectState () {
      let StateReference = db.collection('SchoolsData').doc('RegionSchoolD').collection('Countries').doc(this.country.id);
      await StateReference.get().then((doc) => {
        let ListStates = doc.data().States;
        let ids = Object.keys(ListStates);
        let newStates = [];
        ids.forEach(element => {
          newStates.push({ id: element, name: ListStates[element] })
        });
        newStates.sort((a, b) => a.name.localeCompare(b.name));    
        this.states = newStates;
        // aqui reiniciamos los demas selects
        this.SchoolDistrict = [];
      }).catch((error) => {
        console.log(error)
      });
    },
    async SelectSchoolDistrict () {
      let SchoolDistrictReference = db.collection('SchoolsData').doc('RegionSchoolD').collection('Countries').doc(this.country.id).collection('States').doc(this.state.id);
      await SchoolDistrictReference.get().then((doc) => {
        let ListSchoolDistrict = doc.data().SchoolDistricts;
        let ids = Object.keys(ListSchoolDistrict);
        let newSchoolDistricts = [];
        ids.forEach(element => {
          newSchoolDistricts.push({ id: element, name: ListSchoolDistrict[element] })
        });
        newSchoolDistricts.sort((a, b) => a.name.localeCompare(b.name));
        this.SchoolDistrict = newSchoolDistricts;
        // aqui reiniciamos los demas selects
        this.schools = [];
      }).catch((error) => {
        console.log(error)
      });
    },
  },
  computed: {
    formTitle () {
      return this.editedUID === -1 ? 'New School District' : 'Edit School District'
    },
  },
  watch: {
    dialogSchoolDistrict (val) {
      val || this.close()
    },
    dialogLoad (val) {
      console.log(val)
      // if (!val) return
      // console.log(this.$nuxt.isOffline);
      // setTimeout(() => (this.dialogLoad = false), 4000)
    },
  }
}
</script>
